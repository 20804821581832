/* components/form_section.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01h,27mar25,mno  added subtitle to form-section.
01g,22aug24,mno  render with TitleText component.
01f,18may23,mno  show form validation errors, decode input values directly from the form.
                 enabled phone validation.
01e,14oct22,mno  show errors, support tel, date, url fields.
01d,30aug22,mno  named exports.
01c,22jul22,mno  autosized textarea; dropdown; button states; success message.
01b,04oct21,mno  updated form support.
01a,09oct20,mno  created.
*/

import React from "react";
import axios from 'axios';
import { includes, flatten } from 'ramda';
import classNames from 'classnames';
import { notify } from 'react-notify-toast';
import pluralize from 'pluralize';
import { WarningTriangle } from 'iconoir-react';
import TextareaAutosize from 'react-autosize-textarea';
import TextContent from './text_content';
import mapIndexed from '../lib/map_indexed';
import isPresent from '../lib/is_present';
import sectionCx from '../lib/section_cx';
import SectionLayout from '../layouts/section_layout';
import tikklUrl from '../lib/tikkl_url';
import TitleText from './title_text';

/* globals grecaptcha */

const FormField = (props) => {
  const {
    onChange,
    onInvalid,
    field: { fieldType, name, title, mandatory, min, max, hidePlaceholder, selectOptions }
  } = props;

  const fieldCx = classNames('field', `field-${fieldType}`);

  if (includes(fieldType, ['string', 'email', 'url'])) {
    return (
      <div className={fieldCx}>
        <div className="control">
          <input
            className="input"
            type={fieldType}
            name={name}
            placeholder={hidePlaceholder ? null : title}
            required={mandatory}
            onBlur={onChange}
            onInvalid={onInvalid}
            title={`enter a valid ${fieldType}`}
          />
        </div>
      </div>
    );
  }

  if (fieldType === 'tel') {
    return (
      <div className={fieldCx}>
        <div className="control">
          <input
            className="input"
            type={fieldType}
            name={name}
            placeholder={hidePlaceholder ? null : title}
            required={mandatory}
            onBlur={onChange}
            onInvalid={onInvalid}
            pattern="^[+]?[0-9\-\s\(\)]{10,18}$"
            title="enter a 10-digit phone number with country code. Eg, +1 408-888-1212, +91 98887 54321"
          />
        </div>
      </div>
    );
  }

  if (fieldType === 'date') {
    return (
      <div className={fieldCx}>
        <div className="control">
          <label>{title}</label>
          <input
            className="input"
            type={fieldType}
            name={name}
            required={mandatory}
            onBlur={onChange}
            onInvalid={onInvalid}
          />
        </div>
      </div>
    );
  }

  if (fieldType === 'dropdown') {
    return (
      <div className={fieldCx}>
        <div className="control">
          <label>{title}</label>&nbsp;
          <select name={name} required={mandatory} onBlur={onChange} onInvalid={onInvalid}>
            {
              flatten([
                <option key='default' value="">Select one...</option>,
                mapIndexed(
                  (item, idx) => (
                    <option key={`so-${idx}`} value={item}>{item}</option>
                  ),
                  selectOptions
                )
              ])
            }
          </select>
        </div>
      </div>
    );
  }

  if (fieldType === 'textarea') {
    return (
      <div className={fieldCx}>
        <div className="control">
          <TextareaAutosize
            className="textarea"
            type={fieldType}
            name={name}
            placeholder={hidePlaceholder ? null : title}
            required={mandatory}
            onBlur={onChange}
            onInvalid={onInvalid}
            maxLength={max}
            spellCheck
          />
        </div>
      </div>
    )
  }

  return null;
}

const FormSection = (props) => {
  const { onChange, onInvalid, section: { title, subtitle, items, align }} = props;
  const cx = classNames('form-section', {
    'has-text-left':      !align || align === 'left',
    'has-text-right':     align && align === 'right',
    'has-text-centered':  align && align === 'center',
    'has-text-justified': align && align === 'justify',
  });

  return (
    <div className={cx}>
      {
        title
          && <TitleText className="title is-5 form-section-title has-text-weight-semibold" title={title} />
      }
      {
        subtitle
          && <TitleText className="subtitle is-6 mt-1 form-section-subtitle" title={subtitle} />
      }
      {
        mapIndexed(
          (field, idx) => <FormField
            key={`field-${field.fieldType}-${idx}`}
            field={field}
            onChange={onChange}
            onInvalid={onInvalid}
          />,
          items
        )
      }
    </div>
  )
}

const FormButton = (props) => {
  const {
    text, color, isOutline, isSubmitting, errorCount, onClick
  } = props || { color: 'primary', text: 'Submit', isOutline: false, isSubmitting: false, errorCount: 0 };

  const buttonCx = classNames(
    'button is-normal is-rounded',
    `is-${color}`, {
      'is-outline': isOutline,
      'is-loading': isSubmitting
    }
  );

  return (
    <div className="field" onClick={onClick}>
      <div className="control has-text-centered">
        <button name="submit-button" className={buttonCx}>
          <span className="button-text">{text}</span>
        </button>
        {
          errorCount > 0 && (
            <p className="error-message is-flex is-justify-content-center">
              <WarningTriangle color="orange" style={{ marginRight: '0.5em' }}/>
              {errorCount} {pluralize('error', errorCount)} to fix.
            </p>
          )
        }
      </div>
    </div>
  )
};

/*
format: {
  sectionType: 'form'
  title: String
  postTo: URL
  fieldGroups: [
    {
      title: String
      fields: [
        { fieldType, name, title, mandatory, max },
        ...
      ]
    },
    ...
  ]
}
*/

const FormLayout = (props) => {
  const { section, fieldGroups, useLayout } = props;
  const { title, subtitle, description, postTo, formName, ctaButton, backgroundColor, successMessage } = section;
  const [ formState, updateFormState ] = React.useState({ initDone: false, fieldValues: {}, submitDone: false, isSubmitting: false, errorCount: 0 });
  const formRef = React.useRef(null);

  // ComponentDidMount
  /*
  React.useEffect(() => {
    if (!formState.initDone) {
      console.log(`form initialize ...`);
      // clear errors on all form fields.
      Array.from(formRef.current.elements).forEach((f) => {
        console.log(`f:`, f, ' message:', f.validationMessage);
        if (f.validationMessage !== ''){
          f.classList.add('error');
        } else {
          f.classList.remove('error');
        }
      });
      // console.log(formRef.current);
      updateFormState({...formState, initDone: true });
    }
  });

function formData(fields) {
  const data = new FormData();

  for (const key of Object.keys(fields)) {
    data.append(key, fields[key]);
  }
  return data;
}
  */

  const getFormData = (fields) => {
    const fdata = Array.from(formRef.current.elements).reduce(
      (acc, ele) => {
        if (isPresent(ele.value)) {
          acc[ele.name] = ele.value;
        }

        return acc;
      },
      fields || {}
    );

    // console.log(`formData=>${JSON.stringify(fdata)}`);
    return fdata;
  }

  const getErrorCount = () => {
    const count = Array.from(formRef.current?.elements || []).reduce(
      (acc, ele) => ele.validity.valid ? acc : acc + 1,
      0
    );

    // console.log(`error-count: ${count}`);
    return count;
  }

  const onChange = (e) => {
    e.preventDefault();

    // console.log(`onChange: e.target=${e.target.name}->${e.target.value}`);
    const f = e.target;
    if (f.validationMessage === ''){
      f.classList.remove('error');
    } else {
      f.classList.add('error');
    }

    updateFormState({ ...formState, errorCount: getErrorCount() });

    /*
    updateFormState({
      ...formState,
      fieldValues: {...formState.fieldValues, [e.target.name]: e.target.value},
    })
    */

    // console.log(`onChange: state=${JSON.stringify(formState)}\nvalues=${JSON.stringify(getFormData())}`);
  }

  const onInvalid = (e) => {
    e.preventDefault();
    // console.log(`onInvalid: e.target=${e.target.name}->`, e.target.validity);
    e.target.classList.add('error');
  }

  /*
  const onInput = (e) => {
    e.preventDefault();
    console.log(`onInput: e.target=${e.target.name}->`, e.target.validity);
  }
  */

  const onSubmitClick = (e) => {
    // note: allow default processing. The onClick event should bubble up for form-validation and submit to operate.
    updateFormState({...formState, errorCount: getErrorCount() });
  }

  const onSubmit = (e) => {
    const form = e.target;

    e.preventDefault();
    // console.log(`onSubmit: called.`);

    if (formState.isSubmitting) return;

    updateFormState({ ...formState, isSubmitting: true });

    grecaptcha
      .execute(
        process.env.RECAPTCHA_SITE_KEY,
        { action: form.getAttribute('name') }
      )
      .then((recaptcha_token) => axios.post(form.action, getFormData({recaptcha_token})))
      .then((resp) => {
        if (resp.status === 200){
          updateFormState({...formState, submitDone: true, isSubmitting: false });
          form.reset();
          notify.show(
            <div className="notification is-success is-light">
              Your form has been submitted!
            </div>
          );
        } else {
          updateFormState({...formState, isSubmitting: false });
        }
      })
      .catch((error) => alert(error));
  };

  return (
    <SectionLayout useLayout={useLayout}>
      <section className={sectionCx(section, 'section')} style={{ backgroundColor }}>
        {
          (isPresent(title) || isPresent(subtitle) || isPresent(description))
            && <TextContent className="container section-header" {...section} />
        }
        {
          formState.submitDone && <TextContent description={successMessage} />
        }
        {
          formState.submitDone || <form
            ref={formRef}
            name={formName}
            method="post"
            action={tikklUrl(`${postTo}`)}
            onSubmit={onSubmit}
          >
            {
              mapIndexed((s, idx) => (
                <FormSection key={`section-${idx}`} section={s} onChange={onChange} onInvalid={onInvalid} />
              ), fieldGroups)
            }
            <FormButton
              {...ctaButton}
              onClick={onSubmitClick}
              isSubmitting={formState.isSubmitting}
              errorCount={formState.errorCount}
            />
          </form>
        }
      </section>
    </SectionLayout>
  );
};

export default FormLayout;
