/* components/footer.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01l,04mar25,mno  renamed 'Host on Tikkl' to 'Sign up to Host', use full tikkl logo.
01k,09dec24,mno  renamed 'Sign up' to 'Host on Tikkl'
01j,26apr24,mno  renamed 'Browse Events' to 'Find Events'
01j,27mar24,mno  update Twitter logo; removed sub-feature links.
01i,22aug23,mno  added why-tikkl link.
01h,31jul23,mno  added nav to /festivals
01g,13jan23,mno  reorganized footer menu -- 4 cols.
            mno  fixed spelling featured-events
01f,14oct22,mno  added aria-label for social media links.
01e,30aug22,mno  named exports.
01d,20jul22,mno  generic tikkl-footer below marketing footer links.
01c,08dec21,mno  added signup and FAQ links.
01b,16nov21,mno  added instagram.
01a,09oct20,mno  created.
*/

import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faInstagram,
  faXTwitter,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import tikklUrl from '../lib/tikkl_url';

const currentYear = () => (new Date()).getFullYear();

const TikklFooter = () => {
  const tikklFaces = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "tikkl-logo.png" }){
        childImageSharp {
          fixed(height: 28) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }`
  );

  return (
    <footer className="tikkl-footer footer px-5">
      <div className="footer-section">
        <div className="">
          <Img className="tikkl-logo-full" fixed={tikklFaces.file.childImageSharp.fixed} alt="Tikkl Logo" />
          <a className="ml-2 with-separator" href={tikklUrl('/events')}>Find Events</a>
          <a className="with-separator" href="/signup">Host Your Own Event</a>
          <a className="" href="https://tikkl.zendesk.com">Help Desk</a>
        </div>
        <ul className="footer-links social-media-links mt-2">
          <li className="instagram"><a href="https://www.instagram.com/tikklinc"><FontAwesomeIcon aria-label="Instagram" icon={faInstagram}/></a></li>
          <li className="facebook"><a href="https://www.facebook.com/TikklInc" title="Facebook"><FontAwesomeIcon aria-label="Facebook" icon={faFacebook}/></a></li>
          <li className="twitter"><a href="https://twitter.com/tikkl" title="Twitter"><FontAwesomeIcon aria-label="Twitter" icon={faXTwitter}/></a></li>
          <li className="linked-in"><a href="https://www.linkedin.com/company/tikkl" title="LinkedIn"><FontAwesomeIcon aria-label="LinkedIn" icon={faLinkedin}/></a></li>
        </ul>
      </div>

      <div className="footer-section footer-nav">
        <div className="copyright is-block">
          &copy;
          {currentYear()}
          &nbsp;
          <a href="/">Tikkl Inc.</a>
          &nbsp;
          <span>All rights reserved.</span>
        </div>

        <ul className="footer-links">
          <li><a className="with-separator" href="/tos">Terms of Service</a></li>
          <li><a className="with-separator" href="/privacy">Privacy Policy</a></li>
          <li><a className="" href="/anti_spam">Anti Spam</a></li>
        </ul>
      </div>
    </footer>
  );
};

const appFooter = () => (
  <footer className="footer">
    <div className="container">
      <div className="columns">
        <div className="column">
          <ul>
            <li className="title is-7 is-primary is-uppercase">For Event-Goers</li>
            <li><a href={tikklUrl('/events')}>Find Events</a></li>
          </ul>
        </div>
        <div className="column">
          <ul>
            <li className="title is-7 is-primary is-uppercase">For Event Organizers</li>
            <li><Link to="/whytikkl">Why Host on Tikkl</Link></li>
            <li><Link to="/features">Features</Link></li>
            <li><Link to="/pricing">Pricing</Link></li>
            <li><Link to="/signup">Sign up to Host</Link></li>

          </ul>
        </div>
        <div className="column">
          <ul>
            <li className="title is-7 is-primary is-uppercase">Support</li>
            <li><a href={tikklUrl('/tikkl')}>Blog</a></li>
            <li><Link to="/brand">Brand Guidelines</Link></li>
            <li><a href="https://tikkl.zendesk.com/">FAQs</a></li>
            <li><a href="https://tikkl.zendesk.com/">Help Desk</a></li>
          </ul>
        </div>
        <div className="column">
          <ul>
            <li className="title is-7 is-primary is-uppercase">Company</li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/team">Team</Link></li>
            <li><Link to="/news">In the News</Link></li>
            <li><Link to="/jobs">Jobs</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);


const Footer = (_props) => (
  <span>
    {appFooter()}
    {TikklFooter()}
  </span>
);

export default Footer;
